<template>
<div>
    <template v-for="(item,index) in this.$store.state.operator.ordersList">
        <OperatorOrderItem :key="index" :nextStatus='5' currentStatus='status_id=[4]' :item="item"/>
    </template>
    
</div>
</template>

<script>
import OperatorOrderItem from '@/components/OperatorOrderItem.vue'
export default {
    components: {
        OperatorOrderItem
    },
    head: {
        title: {
            inner: 'Собрано',
            separator: '-',
            complement: 'Оператор'
        },
    },
    async mounted() {
        console.log(this.$route.path)
           await this.$store.dispatch("actionGetListOrder", 'status_id=[4]');
    },
}
</script>
